export default {
    camanchacaTitle: { id: 'solids_turbidity_monitoring' },
    paragraph1: { id: 'water_paragraph_1' },
    paragraph2: { id: 'water_paragraph_2' },
    paragraph3: { id: 'water_paragraph_3' },
    paragraph4: { id: 'water_paragraph_4' },
    paragraph4Link: { id: 'water_paragraph_4_link' },
    paragraph5: { id: 'water_paragraph_5' },
    paragraph5Link: { id: 'water_paragraph_5_link' },
    summaryTitle: { id: 'what_we_measure' },
    effluentParameter: { id: 'effluent_parameter' },
    overRatioDescription: { id: 'over_ratio_description' },
    solidsDescription: { id: 'solids_description' },
    turbidityDescription: { id: 'turbidity_description' },
    turbidityDatasetLabel: { id: 'turbidity_dataset_label' },
    turbidityMaxLevelDatasetLabel: { id: 'turbidity_max_level_dataset_label' },
    solidsDatasetLabel: { id: 'solids_dataset_label' },
    solidsMaxLevelDatasetLabel: { id: 'solids_max_level_dataset_label' },
    exportSensorDataBtnTitle: { id: 'export_sensor_data_btn_title' },
    statusSuccess: { id: 'status_success' },
    statusFailure: { id: 'status_failure' },
}
