import React, {Component} from 'react';
import T from 'prop-types';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';
import {Route, Redirect} from 'react-router-dom';
import IntlProvider from 'components/IntlProvider/IntlProvider';
import * as ROUTE from 'constants/appRouting';
import EctoWater from './EctoWater';

export default class Root extends Component {
    static propTypes = {
        store: T.object.isRequired,
        history: T.object.isRequired
    };

    componentDidMount() {
        this.checkAuthorized();
    }

    checkAuthorized = () => {
        const {history} = this.props;
        history.replace(ROUTE.ECTO_WATER_PETROHUE);
    };

    render() {
        const {store, history} = this.props;

        return (
            <Provider store={store}>
                <IntlProvider>
                    <ConnectedRouter store={store} history={history}>
                        <div className="app">
                            <Route exact path="/" />
                            <Route path={ROUTE.ECTO_WATER_PETROHUE} component={EctoWater} />
                        </div>
                    </ConnectedRouter>
                </IntlProvider>
            </Provider>
        );
    }
}
