import React, { Component } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { connect } from 'react-redux';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import enFrontend from 'i18n/frontend.en.json';
import esFrontend from 'i18n/frontend.es.json';
import enWater from 'i18n/water.en.json';
import esWater from 'i18n/water.es.json';
import moment from 'moment';

const messages = {
    en: { ...enFrontend, ...enWater },
    es: { ...esFrontend, ...esWater },
};

addLocaleData([...es, ...en]);

class ReactIntlProvider extends Component {
    render() {
        const { lang, children, defaultLang } = this.props;
        const currentLang = lang || defaultLang;
        moment.locale(currentLang);

        return (
            <IntlProvider
                locale={currentLang}
                defaultLocale={defaultLang}
                messages={messages[currentLang]}
            >
                {children}
            </IntlProvider>
        );
    }
}

const enhance = connect((state) => ({
    defaultLang: state.getIn(['locale', 'defaultLang']),
    lang: state.getIn(['locale', 'lang']),
}));

export default enhance(ReactIntlProvider);
