import React, {Component} from "react";
import FilterObserver from "./FilterObserver";
import Chart from "./Chart";
import FilterBar from "./FilterBar";
import ExportButton from "./ExportButton";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {ifWindowWidthLessThan} from "helpers/responsive";

class ChartFirst extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableButton: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const data = this.props.data.get('datasets');

        if (prevProps.data.get('datasets') !== data && data.length > 0) {
            let disable = false;

            if (data[0].message !== '') {
                disable = true;
            }

            this.setState({disableButton: disable})
        }
    }

    render() {
        const data = this.props.data.get('datasets');

        return (
            <div className="widget chart">
                <FilterObserver/>
                {ifWindowWidthLessThan(791) && data.length > 0 && data[0].message !== '' &&
                    <div className="btn-panel mobile-chart-message">{data[0].message}</div>
                }
                <div className="btn-panel">
                    <ExportButton disableButton={this.state.disableButton} />
                </div>
                <Chart data={this.props.data} />
                <FilterBar/>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        data: state.getIn(['ectoWater', 'firsChartSensorData']),
    })
)(injectIntl(ChartFirst));

