import Immutable, {Map} from 'immutable';
import {handleActions} from 'redux-actions';
import ActionHelper from 'helpers/action';
import {getDefaultChartSensorData, getDefaultDateRange} from 'helpers/ectoWater';

// constants
export const SET_FIRST_DATE_RANGE = 'ectoWater/SET_FIRST_DATE_RANGE';
export const SET_SECOND_DATE_RANGE = 'ectoWater/SET_SECOND_DATE_RANGE';
export const FETCH_FIRST_SENSORS_DATA = 'ectoWater/FETCH_FIRST_SENSORS_DATA';
export const SUCCESS_FIRST_SENSORS_DATA = 'ectoWater/SUCCESS_FIRST_SENSORS_DATA';
export const FAILURE_FIRST_SENSORS_DATA = 'ectoWater/FAILURE_FIRST_SENSORS_DATA';
export const FETCH_SECOND_SENSORS_DATA = 'ectoWater/FETCH_SECOND_SENSORS_DATA';
export const SUCCESS_SECOND_SENSORS_DATA = 'ectoWater/SUCCESS_SECOND_SENSORS_DATA';
export const FAILURE_SECOND_SENSORS_DATA = 'ectoWater/FAILURE_SECOND_SENSORS_DATA';

// actions
export const setFirstDateRange = ActionHelper.createActionObj(SET_FIRST_DATE_RANGE);
export const setSecondDateRange = ActionHelper.createActionObj(SET_SECOND_DATE_RANGE);
export const fetchFirstSensorsData = ActionHelper.createActionObj(FETCH_FIRST_SENSORS_DATA);
export const fetchSecondSensorsData = ActionHelper.createActionObj(FETCH_SECOND_SENSORS_DATA);

// store
const initialState = Immutable.fromJS({
    firstChartLoader: false,
    secondChartLoader: false,
    firsDateRange: getDefaultDateRange(),
    secondDateRange: getDefaultDateRange(),
    firsChartSensorData: getDefaultChartSensorData(),
    secondChartSensorData: getDefaultChartSensorData(),
});

const ectoWater = handleActions(
    {
        [FETCH_FIRST_SENSORS_DATA]: (state) => {
            return state.set('firstChartLoader', true);
        },
        [FETCH_SECOND_SENSORS_DATA]: (state) => {
            return state.set('secondChartLoader', true);
        },
        [SET_FIRST_DATE_RANGE]: (state, action) => {
            return state.set('firsDateRange', Map(action.payload));
        },
        [SET_SECOND_DATE_RANGE]: (state, action) => {
            return state.set('secondDateRange', Map(action.payload));
        },
        [SUCCESS_FIRST_SENSORS_DATA]: (state, action) => {
            return state
                .set('firsChartSensorData', Map(action.payload))
                .set('firstChartLoader', false);
        },
        [FAILURE_FIRST_SENSORS_DATA]: (state) => {
            return state
                .set('firsChartSensorData', Map(getDefaultChartSensorData()))
                .set('firstChartLoader', false);
        },
        [SUCCESS_SECOND_SENSORS_DATA]: (state, action) => {
            return state
                .set('secondChartSensorData', Map(action.payload))
                .set('secondChartLoader', false);
        },
        [FAILURE_SECOND_SENSORS_DATA]: (state) => {
            return state
                .set('secondChartSensorData', Map(getDefaultChartSensorData()))
                .set('secondChartLoader', false);
        }
    },
    initialState
);

export default ectoWater;
