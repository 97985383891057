import React, {Component} from 'react';
import {connect} from "react-redux";
import {injectIntl} from 'react-intl';
import messages from 'components/EctoWater/translate';
import BaseChart from 'components/EctoWater/Common/LineChart';

class Chart extends Component {
    render() {
        const { intl: { formatMessage }, data, loader} = this.props;

        return (
            <BaseChart
                loading={loader}
                data={data}
                params={{
                    label: formatMessage(messages.turbidityDatasetLabel),
                    maxLineLabel: formatMessage(messages.turbidityMaxLevelDatasetLabel),
                    maxLineColor: '#FF5B5B',
                    color: '#25BEF0'
                }}
            />
        );
    }
}

export default connect(
    (state) => ({
        loader: state.getIn(['ectoWater', 'firstChartLoader']),
    })
)(injectIntl(Chart));
