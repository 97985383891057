import React, {Component} from "react";
import {connect} from "react-redux";
import {ECTO_PETROHUE_ID, ECTO_TURBIDIRY_SENSORS} from "constants/appParams";
import BaseExportButton from 'components/EctoWater/Common/ExportButton';

class ExportButton extends Component{
    render() {
        const {dateRange, disableButton} = this.props;

        return (
            <BaseExportButton
                site={ECTO_PETROHUE_ID}
                sensor={ECTO_TURBIDIRY_SENSORS[0]}
                dateRange={dateRange}
                disableButton={disableButton}
            />
        );
    }
}

export default connect(
    (state) => ({
        dateRange: state.getIn(['ectoWater', 'firsDateRange']),
    }),
)(ExportButton);
