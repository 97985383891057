import React, {Component} from 'react';
import {Line, Chart} from 'react-chartjs-2';
import {ifWindowWidthLessThan, getChartsRatio} from 'helpers/responsive';
import Loader from 'components/common/Loading/Loader';

class LineChart extends Component {
    getTimeFormat = (label) => {
        const timeFormat = {
            format: 'DD-MM-YYYY',
            unit: 'day'
        }

        if ((new RegExp(/\d{2}-\d{2}-\d{4} \d{2}:\d{2}/)).test(label)) {
            timeFormat.format = 'DD-MM-YYYY HH:mm';
            timeFormat.unit = 'hour';
        }

        return timeFormat;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const data = this.props.data.get('datasets');

        if (data.length > 0 && prevProps.data.get('datasets') !== data) {
            const { message } = data[0];

            if (!ifWindowWidthLessThan(791)) {
                Chart.pluginService.register({
                    beforeDraw: function(chart, easing) {
                        const ctx = chart.ctx;
                        const lines = message.split('\n');
                        let font = 14;
                        let x = 150;
                        let y = 50;
                        let cornerRadius = 10;
                        let lineHeight = 15;
                        let width = ctx.measureText(lines[0]).width+font*2;
                        let height = font*(lines.length+1);
                        ctx.textAlign = "left";
                        ctx.font = `bold ${font}px Arial`;
                        ctx.fillStyle = "#25BEF0";
                        roundRect(ctx, x-font, y-font, width, height, cornerRadius, true, false);
                        ctx.fillStyle = '#63687C';
                        if (message !== '') {
                            for (let i = 0; i<lines.length; i++)
                                ctx.fillText(lines[i], x, y + (i*lineHeight) );
                        } else {
                            chart.clear();
                        }
                    }
                });

                function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
                    if (typeof stroke === 'undefined') {
                        stroke = true;
                    }
                    if (typeof radius === 'undefined') {
                        radius = 5;
                    }
                    if (typeof radius === 'number') {
                        radius = {tl: radius, tr: radius, br: radius, bl: radius};
                    } else {
                        let defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
                        for (let side in defaultRadius) {
                            radius[side] = radius[side] || defaultRadius[side];
                        }
                    }
                    ctx.beginPath();
                    ctx.moveTo(x + radius.tl, y);
                    ctx.lineTo(x + width - radius.tr, y);
                    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
                    ctx.lineTo(x + width, y + height - radius.br);
                    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
                    ctx.lineTo(x + radius.bl, y + height);
                    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
                    ctx.lineTo(x, y + radius.tl);
                    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
                    ctx.closePath();
                    if (fill) {
                        ctx.fill();
                    }
                    if (stroke) {
                        ctx.stroke();
                    }
                }
            }
        }
    }

    getChartParams = () => {
        const data = this.props.data.get('datasets');
        const labels = this.props.data.get('dates');
        const {label, maxLineLabel, maxLineColor, color} = this.props.params;
        const datasets = [];

        if (data !== undefined) {
            const dataLength = data.length;

            for (let key = 0; key < dataLength; key++) {
                const {rows} = data[key];

                for (let key2 = 0; key2 < rows.length; key2++) {
                    const dataset = {
                        lineTension: 0,
                        label: label,
                        fill: false,
                        tooltip: false,
                        backgroundColor: color,
                        borderColor: color,
                        pointRadius: 3,
                        pointHitRadius: 3,
                        pointHoverRadius: 3,
                        data: rows[key2].row
                    };

                    datasets.push(dataset);

                    const max_value_set = rows[key2].max_value_set;

                    if (max_value_set !== null) {
                        const maxValueDatasetData = [];
                        for (let rowKey = 0; rowKey < rows[key2].row.length; rowKey++) {
                            let rowData = rows[key2].row[rowKey];
                            maxValueDatasetData.push({'x': rowData.x, 'y': max_value_set})
                        }

                        datasets.push({
                            type: 'line',
                            label: maxLineLabel,
                            fill: false,
                            pointRadius: 0,
                            pointHitRadius: 0,
                            pointBorderWidth: 0,
                            borderDash: [10, 5],
                            borderColor: maxLineColor,
                            offset: false,
                            data: maxValueDatasetData
                        });
                    }
                }
            }
        }

        return {
            labels,
            datasets,
            timeFormat: this.getTimeFormat(labels[0])
        };
    };

    chartData = ({labels, datasets}) => {
        return {
            labels,
            datasets
        }
    };

    chartOptions = ({timeFormat}) => {
        return ({
            aspectRatio: getChartsRatio(),
            responsive: true,
            maintainAspectRatio: true,
            legend: {
                display: true,
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        parser: timeFormat.format,
                        unit: timeFormat.unit,
                        displayFormats: {
                            hour: timeFormat.format,
                        }
                    },
                    stacked: true,
                    scaleLabel: {
                        display: false
                    },
                    offset: false,
                }],
                yAxes: [{
                    scaleLabel: {
                        display: false,
                    },
                }],
            },
            tooltips: {
                enabled: true,
            },
            plugins: {
                datalabels: {
                    display: false,
                    anchor: 'start',
                    color: 'black',
                    align: function (context) {
                        let index = context.dataIndex;
                        let value = context.dataset.data[index];
                        return value < 10 ? 'end' : 'start'
                    },
                    offset: 1,
                    labels: {
                        value: {
                            font: {
                                weight: 'bold',
                                size: ifWindowWidthLessThan(1280) ? 9 : 11
                            }
                        }
                    },
                }
            }
        });
    };

    render() {
        const params = this.getChartParams();
        return (
            <div className="chart-box">
                <Loader loading={this.props.loading}/>
                <Line
                    width={null}
                    height={null}
                    data={this.chartData(params)}
                    options={this.chartOptions(params)}
                />
            </div>
        );
    }
}

export default LineChart;
