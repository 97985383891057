import React from 'react';
import { injectIntl } from 'react-intl';
import messages from 'components/EctoWater/translate';
import cn from 'classnames';

const Summary = (props) => {
    const { records: {solids, turbidity}, intl: { formatMessage }} = props;

    return (
        <div className="summary">
            <div className="status">
                <span className="status-item status-success">{formatMessage(messages.statusSuccess)}</span>
                <span className="status-item status-failure">{formatMessage(messages.statusFailure)}</span>
            </div>
            <ul className="summary-list">
                <li className="head">
                    <span className="name">{formatMessage(messages.summaryTitle)}</span>
                </li>
                <li className="list-item info-item">
                    <span className="name">{formatMessage(messages.effluentParameter)}</span>
                    <span className="value">{formatMessage(messages.overRatioDescription)}</span>
                </li>
                <li className="list-item">
                    <span className="name">{formatMessage(messages.turbidityDescription)}</span>
                    <span className={cn('value', turbidity.colorClass)}>{turbidity.value}</span>
                </li>
                <li className="list-item">
                    <span className="name">{formatMessage(messages.solidsDescription)}</span>
                    <span className={cn('value', solids.colorClass)}>{solids.value}</span>
                </li>
            </ul>
        </div>
    );
}

export default injectIntl(Summary);
