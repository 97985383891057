import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import messages from 'components/EctoWater/translate';
import {Description} from "@material-ui/icons";
import {Button} from '@material-ui/core';
import * as ApiRouting from 'constants/apiRouting';
import UrlHelper from 'helpers/url';
import { checkWeekFormat } from 'helpers/ectoWater';

const API = process.env.REACT_APP_API_URL;

class ExportButton extends Component {
    render() {
        const {site, sensor, dateRange, intl: { formatMessage }, disableButton} = this.props;
        const dateFrom = dateRange.get('from');
        const dateTo = dateRange.get('to');
        const withTime = checkWeekFormat(dateFrom, dateTo);
        const uri = withTime ? ApiRouting.EXPORT_CSV_SENSORS_DATA_WITH_TIME : ApiRouting.EXPORT_CSV_SENSORS_DATA;

        const url = UrlHelper.create(uri, {
            site,
            sensor,
            from: dateFrom,
            to: dateTo,
        });

        return (
            <Button
                variant="contained"
                className="export-btn"
                startIcon={<Description/>}
                href={API + url}
                disabled={disableButton}
            >
                {formatMessage(messages.exportSensorDataBtnTitle)}
            </Button>
        );
    }
}

export default injectIntl(ExportButton);
