import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import DateRangeInput from 'components/common/Form/DateRange';
import { setFirstDateRange } from 'reducers/ectoWater/reducer';
import { checkDateFormat } from 'helpers/ectoWater';

class FilterBar extends Component {

    onDateRangeChange = (range) => {
        if (range) {
            const date = checkDateFormat(range);

            this.props.setFirstDateRange(date);
        }
    };

    render() {

        return (
            <Grid container spacing={1} className="module-filter">

                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <DateRangeInput onChange={this.onDateRangeChange} />
                </Grid>

            </Grid>
        );
    }
}

export default connect(
    null,
    {
        setFirstDateRange
    }
)(injectIntl(FilterBar));
