import moment from "moment";
import 'moment-timezone';

export function getDefaultChartSensorData() {
    return {
        datasets: [],
        dates: [],
        summary: []
    }
}

export function getDefaultDateRange() {
    return {
        from: moment().subtract(1, "days"),
        to: moment(),
    }
}

export function checkDateFormat(range) {
    let {startDate, endDate} = range

    const withTime = checkWeekFormat(startDate, endDate);
    const dateFormat = withTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';

    let startDateMom = moment(startDate).utc().format(dateFormat);

    if (withTime) {
        if (moment(endDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
            endDate = moment(new Date()).utc().format(dateFormat);
        } else {
            endDate = moment.tz(`${moment(endDate).format('YYYY-MM-DD')}T23:59:59`, 'America/Santiago').utc().format(dateFormat);
            startDateMom = moment.tz(`${moment(startDate).format('YYYY-MM-DD')}T00:00:00`, 'America/Santiago').utc().format(dateFormat);
        }
    } else {
        endDate = moment(endDate).utc().format(dateFormat);
    }

    return {
        from: startDateMom,
        to: endDate,
    }
}

export function checkWeekFormat(dateFrom, dateTo) {
    return moment(dateTo).diff(moment(dateFrom), 'days') <= 7;
}

