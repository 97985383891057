export default class UrlHelper {
    /**
     * @param url - UrlString. Example: /custom/{:param1}/{:param2}
     * @param params - Params object. Example: {
     *     param1 : 12,
     *     param2 : "update"
     * }
     * @returns string - Example: /custom/12/update
     */
    static create(url, params) {
        if(!params || typeof params !== "object") {
            return url;
        }

        const createArrayUrl = (key, params) => {
            let paramQuery = '';
            params.map( item => paramQuery = paramQuery + (paramQuery ? '&' : '') + `${key}[]=${item}`);

            return paramQuery;
        }

        for(let key in params) {
            url = url.replace(
                new RegExp(
                    `{:${key}}`,
                    'g'
                ),
                (Array.isArray(params[key]) ? createArrayUrl(key, params[key]) : params[key])
            );
        }

        return url;
    }

    static getPsfsQuery(payload) {

        if(typeof payload === 'object' && payload.hasOwnProperty('psfs')) {
            return `?${payload.psfs.queryParams}`;
        }

        return '';
    }
}