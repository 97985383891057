import React, {Component} from 'react';
import {connect} from "react-redux";
import {injectIntl} from 'react-intl';
import messages from 'components/EctoWater/translate';
import Summary from 'components/EctoWater/Preview/Summary';
import CamanchacaLogo from 'assets/images/camanchaca-logo.png';
import Farm from 'assets/images/farm.svg';

class Preview extends Component {
    setRecordValue = (summary, recordValue) => {
        if (summary !== undefined && summary.length > 0 && summary[0].records_over_max !== 'N/A') {
            recordValue.value = `${summary[0].records_over_max.toFixed(2)}%`;
            recordValue.colorClass = summary[0].records_over_max > 2.5 ? 'color-red' : 'color-green';
        } else {
            recordValue.value = 'N/A';
            recordValue.colorClass = 'color-gray';
        }
    }

    getRecordsOverMax = () => {
        const turbiditySummary = this.props.firsChartSensorData.get('summary');
        const solidsSummary = this.props.secondChartSensorData.get('summary');
        const recordsOverMax = {
            solids: {},
            turbidity: {},
        };

        this.setRecordValue(solidsSummary, recordsOverMax.solids);
        this.setRecordValue(turbiditySummary, recordsOverMax.turbidity);

        return recordsOverMax;
    };

    render() {
        const {intl: {formatMessage}} = this.props;

        return (
            <div className="widget preview">
                <div className="preview-description">
                    <img src={CamanchacaLogo} alt="Camanchaca logo" className="company-logo"/>
                    <div className="description-title">{formatMessage(messages.camanchacaTitle)}</div>
                    <div className="description-text">{formatMessage(messages.paragraph1)}</div>
                    <div className="description-text">{formatMessage(messages.paragraph2)}</div>
                    <div className="description-text">{formatMessage(messages.paragraph3)}</div>
                    <div className="description-text">
                        {formatMessage(messages.paragraph4)}
                        <a href='https://salmondata.solmat.cl/descargables/actas_bitacoras_comunidades'>
                            {formatMessage(messages.paragraph4Link)}
                        </a>
                    </div>
                    <div className="description-text">
                        {formatMessage(messages.paragraph5)}
                        <a href='https://salmondata.solmat.cl/descargables/actas_bitacoras_comunidades'>
                            {formatMessage(messages.paragraph5Link)}
                        </a>
                    </div>
                </div>
                <div className="preview-table">
                    <img src={Farm} alt="Camanchaca logo"/>
                    <Summary records={this.getRecordsOverMax()}/>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        firsChartSensorData: state.getIn(['ectoWater', 'firsChartSensorData']),
        secondChartSensorData: state.getIn(['ectoWater', 'secondChartSensorData']),
    })
)(injectIntl(Preview));
