import React, {Component} from "react";
import {connect} from "react-redux";
import {fetchSecondSensorsData} from "reducers/ectoWater/reducer";
import {ECTO_PETROHUE_ID, ECTO_SOLIDS_SENSOR_TYPE, ECTO_SOLIDS_SENSORS} from 'constants/appParams';
import BaseFilterObserver from 'components/EctoWater/Common/FilterObserver';

class FilterObserver extends Component {
    render() {
        const {dateRange, fetchSecondSensorsData} = this.props

        return (
            <BaseFilterObserver
                site={ECTO_PETROHUE_ID}
                sensors={ECTO_SOLIDS_SENSORS}
                sensor_types={ECTO_SOLIDS_SENSOR_TYPE}
                dateRange={dateRange}
                fetchSensorsData={fetchSecondSensorsData}
            />
        );
    }
}

export default connect(
    (state) => ({
        dateRange: state.getIn(['ectoWater', 'secondDateRange']),
    }),
    {
        fetchSecondSensorsData
    }
)(FilterObserver);
