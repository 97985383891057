import React, {Component} from 'react';
import {connect} from "react-redux";
import {injectIntl} from 'react-intl';
import messages from 'components/EctoWater/translate';
import BaseChart from 'components/EctoWater/Common/LineChart';

class Chart extends Component {
    render() {
        const { intl: { formatMessage }, data, loader} = this.props;

        return (
            <BaseChart
                loading={loader}
                data={data}
                params={{
                    label: formatMessage(messages.solidsDatasetLabel),
                    maxLineLabel: formatMessage(messages.solidsMaxLevelDatasetLabel),
                    maxLineColor: '#D94545',
                    color: '#63687C'
                }}
            />
        );
    }
}

export default connect(
    (state) => ({
        loader: state.getIn(['ectoWater', 'secondChartLoader']),
    })
)(injectIntl(Chart));
