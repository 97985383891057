import {createStore, applyMiddleware, compose} from 'redux';
import {Map} from 'immutable';
import {routerMiddleware} from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import * as H from 'history';
import rootReducer from 'reducers';
import rootSaga from 'reducers/sagas';

export const history = H.createBrowserHistory();
const initialState = Map();
const enhancers = [];
const reactRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middleware = [
    sagaMiddleware,
    reactRouterMiddleware
];

if (process.env.NODE_ENV === 'development') {
    const {__REDUX_DEVTOOLS_EXTENSION__} = window;

    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
        enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const store = createStore(rootReducer, initialState, composedEnhancers);
sagaMiddleware.run(rootSaga);

export default store;
