import React from 'react';
import {render} from 'react-dom';
import Root from 'routes';
import store, {history} from 'store';

import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});
}

render(
    <Root store={store} history={history}/>,
    document.getElementById("root")
);
