import React, { Component } from 'react';
import { subDays, subHours, subMonths, isSameDay, isToday, endOfToday } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import T from 'prop-types';
import Button from '@material-ui/core/Button';
import messages from './translate';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-date-range/dist/styles.css';
import 'styles/app/components/date-range.scss'

class DateRangeInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            range: [{
                key: 'selection',
                startDate: subHours(new Date(), 24),
                endDate: new Date(),
                label: this.props.intl.formatMessage(messages.day_1)
            }],
            rangePopup: {
                isOpen: false,
                anchor: null
            },
            width: window.innerWidth,
            monthCount: 2,
            showMonthAndYear: false,
            direction: this.props.direction
        }
        this.dateRangeBox = React.createRef();
        this.dateRangeField = React.createRef();
    }

    static defaultProps = {
        startDate: new Date(),
        endDate: new Date(),
        direction: 'horizontal'
    };

    componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
        document.addEventListener('click', (e) => this.onClickRangeOutside(e), false);
        this.props.onChange(this.state.range[0]);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        document.removeEventListener('click', this.onClickRangeOutside, false);

        this.dateRangeBox = null;
        this.dateRangeField = null;
    }

    onClickRangeOutside(event) {
        const { target } = event;

        if (this.dateRangeField && this.dateRangeBox) {
            const [ dateRangeField, dateRangeBox ] = [ this.dateRangeField.current, this.dateRangeBox.current ];

            if (dateRangeBox && !dateRangeField.contains(target) && !dateRangeBox.contains(target)) {
                this.onCloseRangePopup();
            }
        }
    }

    setRangePopupState = (isOpen, anchor) => {
        this.setState({
            rangePopup: {
                isOpen: isOpen,
                anchor: anchor
            }
        })
    };

    onResize = () => {
        const { width } = this.state;

        if (width <= 1200) {
            this.setState({ monthCount: 2 });
        }

        if (width <= 768) {
            this.setState({
                showMonthAndYear: true,
                direction: 'vertical'
            });
        }

        if (width <= 480) {
            this.setState({ monthCount: 1 });
        }

        this.setState({
            width: window.innerWidth
        });
    };

    onFocus = (e) => {
        this.setRangePopupState(true, e.currentTarget);
    };

    onCloseRangePopup = () => {
        const { range } = this.state

        this.props.onChange(range[0]);
        this.setRangePopupState(false, null);
    };

     staticRangeLabel = () => {
         const { intl: { formatMessage } } = this.props;
         return [
             {
                 label: formatMessage(messages.day_1),
                 range: () => ({ startDate: subHours(new Date(), 24), endDate: new Date(), label: formatMessage(messages.day_1) }),
                 isSelected(range) {
                     const definedRange = this.range();
                     return (
                         isSameDay(range.startDate, definedRange.startDate) &&
                         isSameDay(range.endDate, definedRange.endDate)
                     );
                 },
             },
             {
                 label: formatMessage(messages.days_7),
                 range: () => ({ startDate: subDays(new Date(), 6), endDate: new Date(), label: formatMessage(messages.days_7) }),
                 isSelected(range) {
                     const definedRange = this.range();
                     return (
                         isSameDay(range.startDate, definedRange.startDate) &&
                         isSameDay(range.endDate, definedRange.endDate)
                     );
                 },
             },
             {
                 label: formatMessage(messages.month_1),
                 range: () => ({ startDate: subMonths(new Date(), 1), endDate: new Date(), label: formatMessage(messages.month_1) }),
                 isSelected(range) {
                     const definedRange = this.range();
                     return (
                         isSameDay(range.startDate, definedRange.startDate) &&
                         isSameDay(range.endDate, definedRange.endDate)
                     );
                 },
             },
             {
                 label: formatMessage(messages.months_3),
                 range: () => ({ startDate: subMonths(new Date(), 3), endDate: new Date(), label: formatMessage(messages.months_3) }),
                 isSelected(range) {
                     const definedRange = this.range();
                     return (
                         isSameDay(range.startDate, definedRange.startDate) &&
                         isSameDay(range.endDate, definedRange.endDate)
                     );
                 },
             },
             {
                 label: formatMessage(messages.months_6),
                 range: () => ({ startDate: subMonths(new Date(), 6), endDate: new Date(), label: formatMessage(messages.months_6) }),
                 isSelected(range) {
                     const definedRange = this.range();
                     return (
                         isSameDay(range.startDate, definedRange.startDate) &&
                         isSameDay(range.endDate, definedRange.endDate)
                     );
                 },

             }
         ]};

    onChangeRange = (data) => {
        const selection = data.selection;
        const {startDate, endDate, label} = selection;
        const { intl: { formatMessage } } = this.props;
        const { range } = this.state;

        const labelsList = this.staticRangeLabel().map(i => i.label);

        if (label !== range[0].label && labelsList.includes(label)) {
            selection.label = label;
        } else {
            selection.label = formatMessage(messages.customDate);
        }

        if (isSameDay(startDate, endDate) && isToday(endDate && startDate)) {
            selection.endDate = endOfToday();
            selection.startDate = subHours(new Date(), 24);
        }

        this.setState({ range: [selection] });
    };

    render() {
        const { range, rangePopup: { isOpen, anchor }, monthCount, direction, showMonthAndYear } = this.state;

        return (
            <FormControl
                component={'div'}
                ref={this.dateRangeField}
                disabled={this.props.disabled}
                onFocus={this.onFocus}
            >
                <Input
                    readOnly
                    className="date-range-field"
                    value={range[0].label}
                />

                <Popper open={isOpen} anchorEl={anchor} placement="bottom-start" transition className="date-range-box">
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps} timeout={350}>
                            <Paper className="date-range" ref={this.dateRangeBox}>
                                <DateRangePicker
                                    onChange={this.onChangeRange}
                                    months={monthCount}
                                    weekStartsOn={1}
                                    monthDisplayFormat={'MMMM'}
                                    minDate={subMonths(new Date(), 6)}
                                    maxDate={new Date()}
                                    direction={direction}
                                    ranges={range}
                                    inputRanges={[]}
                                    staticRanges={this.staticRangeLabel()}
                                    showMonthAndYearPickers={showMonthAndYear}
                                    showSelectionPreview={false}
                                    showDateDisplay={true}
                                    moveRangeOnFirstSelection={false}
                                />
                                <Button
                                    href={null}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className="date-range-button"
                                    onClick={this.onCloseRangePopup}
                                >
                                    <FormattedMessage {...messages.setupDate} />
                                </Button>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </FormControl>
        )
    }
}

DateRangeInput.propTypes = {
    onChange: T.func.isRequired,
    startDate: T.object.isRequired,
    endDate: T.object.isRequired,
    ranges: T.array,
    direction: T.oneOf(['horizontal', 'vertical']),
};

export default injectIntl(DateRangeInput);
