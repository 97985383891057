import axios from 'axios';
import {call} from 'redux-saga/effects';

const API = process.env.REACT_APP_API_URL;

function* fetchFromAPI(payload) {
    try {
        const res = yield call(axios, `${API}${payload.partUrl}`, payload);

        if (res.status === 204) {
            return 204;
        }

        return res.data;
    } catch (error) {
        let response = error.response;

        if (!response) {
            console.log('SERVER NOT RESPONDING');

            return {
                error: true,
                errors: error.toString(),
            };
        }

        if (response.status === 500) {
            console.log('WHOOPS, SERVER ERROR ', response.data.message);
        }

        return {
            error: error,
            errors: stringifyResponseError(response),
            status: response.status,
            ...response.data,
        };
    }
}

export const getDefaultHeaders = (customHeaders = {}) => {
    return {
        'Content-Type': 'application/json',
        ...customHeaders,
    };
};

const stringifyResponseError = (res) => {
    if (res.data.errors) {
        if (typeof res.data.errors === 'object') {
            return res.data.errors
        }

        let errorString = '';

        res.data.errors.map((error) => {
            return errorString += `${error.field} - ${error.message} `;
        });

        return errorString;
    }

    return res.data.message;
};

export default fetchFromAPI;
