import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import Preview from 'components/EctoWater/Preview';
import ChartFirst from 'components/EctoWater/ChartFirst';
import ChartSecond from 'components/EctoWater/ChartSecond';
import Logo from 'assets/images/ecto-logo.svg';
import 'styles/app/ecto-water.scss';

class EctoWater extends Component {
    render() {

        return (
            <div className="ecto-water">
                <header className="header">
                    <img src={Logo} alt="" className="logo"/>
                    <h1>.WATER - Piscicultura Petrohué</h1>
                </header>
                <div className="content">
                    <Preview />
                    <ChartFirst />
                    <ChartSecond />
                </div>
            </div>
        );
    }
}

export default injectIntl(EctoWater);
