import {put, takeLatest, call} from 'redux-saga/effects';
import fetchFromAPI, {getDefaultHeaders} from 'helpers/fetchFromApi';
import UrlHelper from 'helpers/url';
import {
    FETCH_FIRST_SENSORS_DATA,
    SUCCESS_FIRST_SENSORS_DATA,
    FAILURE_FIRST_SENSORS_DATA,
    FETCH_SECOND_SENSORS_DATA,
    SUCCESS_SECOND_SENSORS_DATA,
    FAILURE_SECOND_SENSORS_DATA
} from './reducer';

function* fetchFirstSensorsData({payload, payload: {uri}}) {
    try {
        const response = yield call(fetchFromAPI, {
            headers: getDefaultHeaders(),
            partUrl: UrlHelper.create(uri, payload)
        });

        if (!response.hasOwnProperty('error')) {
            yield put({type: SUCCESS_FIRST_SENSORS_DATA, payload: response});
        } else {
            yield put({type: FAILURE_FIRST_SENSORS_DATA});
        }
    } catch (error) {
        console.log('FETCH SENSOR DATA FAILED ', error);
    }
}

function* fetchSecondSensorsData({payload, payload: {uri}}) {
    try {
        const response = yield call(fetchFromAPI, {
            headers: getDefaultHeaders(),
            partUrl: UrlHelper.create(uri, payload)
        });

        if (!response.hasOwnProperty('error')) {
            yield put({type: SUCCESS_SECOND_SENSORS_DATA, payload: response});
        } else {
            yield put({type: FAILURE_SECOND_SENSORS_DATA});
        }

    } catch (error) {
        console.log('FETCH SENSOR DATA FAILED ', error);
    }
}

function* ectoWaterSaga() {
    yield takeLatest(FETCH_FIRST_SENSORS_DATA, fetchFirstSensorsData);
    yield takeLatest(FETCH_SECOND_SENSORS_DATA, fetchSecondSensorsData);
}

export default ectoWaterSaga;
