import React from 'react';
import CircleLoader from './CircleLoader';
import PropTypes from 'prop-types';
import './loader.scss';

const Loader = ({loading}) => {
    if (!loading) {
        return null;
    }

    return (
        <div className="loader-container">
            <CircleLoader/>
        </div>
    );
};

Loader.propTypes = {
    loading: PropTypes.bool.isRequired
}

export default Loader;
