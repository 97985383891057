import React, {Component} from "react";
import * as ApiRouting from 'constants/apiRouting';
import { checkWeekFormat } from 'helpers/ectoWater';

class FilterObserver extends Component {
    fetchSensorsData = (props) => {
        const {site, sensors, sensor_types, dateRange, fetchSensorsData} = props;
        const dateFrom = dateRange.get('from');
        const dateTo = dateRange.get('to');
        const withTime = checkWeekFormat(dateFrom, dateTo);

        fetchSensorsData({
            site,
            sensors,
            sensor_types,
            from: dateFrom,
            to: dateTo,
            uri: withTime ? ApiRouting.SENSOR_DATA_WITH_TIME : ApiRouting.SENSOR_DATA
        });
    };

    componentDidUpdate(prevProps) {
        if(prevProps.dateRange !== this.props.dateRange) {
            this.fetchSensorsData(this.props);
        }
    }

    render() {
        return null;
    }
}

export default FilterObserver;
